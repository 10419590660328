<template>
  <div id="product" itemscope itemtype="http://schema.org/Product">
    <chat-bot-icon />
    <pickup-point-modal-window
      @close="toggleMap"
      v-if="visibleMap && partnerPickupPoints.length > 0"
      :header="$t('Pickup points')"
    >
      <pickup-points-viewer :full-screen-mobile="true" :pickup-points="partnerPickupPoints" />
    </pickup-point-modal-window>
    <meta itemprop="sku" :content="getCurrentProduct.sku">
    <section class="px20 product-top-section">
      <div class="container">
        <section class="m0">
          <div class="row">
            <breadcrumbs
              class="pt40 px10"
            />
          </div>
          <div v-if="getPartnerName()" class="row px10 pb20 align-items-center flex mt5">
            <span class="TTCommons-medium cl-matterhorn">{{ $t("Pickup point") }}:</span>
            <span class="TTCommons-light ml5 cl-gray">{{ getPartnerName() }}</span>
            <button v-if="partnerPickupPoints.length" @click="toggleMap" class="p0 brdr-none bg-cl-transparent close mr40 ml5">
              <i class="material-icons info-icon">info_outline</i>
            </button>
          </div>
        </section>
      </div>
      <div class="container">
        <section class="row m0 between-xs">
          <div class="col-xs-12 col-md-6 center-xs middle-xs image relative">
            <no-ssr>
              <product-gallery
                :offline="getOfflineImage"
                :gallery="getProductGallery"
                :configuration="getCurrentProductConfiguration"
                :product="getCurrentProduct"
              />
            </no-ssr>

            <template v-for="(badge, key) in getCurrentProduct.badges ">
              <badge-list :key="`badge-${key}`">
                <product-badge
                  image-style="image-default-product"
                  :alignment="badge.alignment" :file-url="badge.file_url"
                />
              </badge-list>
            </template>
          </div>
          <div class="col-xs-12 col-md-5 data">
            <h1
              class="mb0 mt0 cl-mine-shaft product-name"
              data-testid="productName"
              itemprop="name"
            >
              {{ getCurrentProduct.name | htmlDecode }}
            </h1>
            <div itemprop="offers" class="mb20" itemscope itemtype="http://schema.org/Offer">
              <meta itemprop="priceCurrency" :content="$store.state.storeView.i18n.currencyCode">
              <meta itemprop="price" :content="parseFloat(getCurrentProduct.price_incl_tax).toFixed(2)">
              <meta itemprop="availability" :content="structuredData.availability">
              <meta itemprop="url" :content="getCurrentProduct.url_path">
              <meta itemprop="sku" :content="getCurrentProduct.sku">
              <meta itemprop="image" :content="getCurrentProduct.image">
              <product-price
                class="mt20 mb20"
                v-if="getCurrentProduct.type_id !== 'grouped'"
                :product="getCurrentProduct"
                :custom-options="getCurrentCustomOptions"
              />
              <div class="row">
                <div class="col-xs-12 col-xl-6">
                  <span class="info cl-secondary pointer" @click="openPriceList">
                    <i class="material-icons info-icon ">info_outline</i>
                    <span class="info-label pointer">
                      {{ $t('Show price list') }}
                    </span>
                  </span>
                </div>
                <div class="col-xs-12 col-xl-6">
                  <span v-if="hasBadgePricePerSet(getCurrentProduct)" class="info cl-secondary pointer" @click="openSetDetails">
                    <i class="material-icons info-icon">info_outline</i>
                    <span class="info-label pointer">
                      {{ $t('This is a complete SET') }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="lh20 h5 mb20" itemprop="description">
                <p> {{ getCurrentProduct.description }}</p>
              </div>
              <div class="row">
                <div class="col-xs-12 ">
                  <div class="cl-primary variants  grid-2fr" v-if="getCurrentProduct.type_id =='configurable'">
                    <div
                      class="error"
                      v-if="getCurrentProduct.errors && Object.keys(getCurrentProduct.errors).length > 0"
                    >
                      {{ getCurrentProduct.errors | formatProductMessages }}
                    </div>
                    <div class="h5&quot;" v-for="option in getProductOptions" :key="option.id">
                      <div class="variants-label" data-testid="variantsLabel">
                        {{ getFilterLabel(option.attribute_code) }}: {{ option.label }}
                        <span
                          class="weight-700"
                        >{{ getOptionLabel(option) }}</span>
                      </div>
                      <div class="row top-xs m0 pt10 pb10 variants-wrapper">
                        <div v-if="option.label == 'Color'">
                          <color-selector
                            v-for="filter in getAvailableFilters[option.attribute_code]"
                            :key="filter.id"
                            :variant="filter"
                            :selected-filters="getSelectedFilters"
                            @change="changeFilter"
                          />
                        </div>
                        <div class="sizes" v-else-if="option.label == 'Size'">
                          <size-selector
                            class="mr10 mb10"
                            v-for="filter in getAvailableFilters[option.attribute_code]"
                            :key="filter.id"
                            :variant="filter"
                            :selected-filters="getSelectedFilters"
                            @change="changeFilter"
                          />
                        </div>
                        <div :class="option.attribute_code" v-else>
                          <generic-selector
                            class="mr10 mb10"
                            v-for="filter in getAvailableFilters[option.attribute_code]"
                            :key="filter.id"
                            :variant="filter"
                            :selected-filters="getSelectedFilters"
                            @change="changeFilter"
                          />
                        </div>
                        <span
                          v-if="option.label == 'Size'"
                          @click="openSizeGuide"
                          class="p0 ml30 inline-flex middle-xs no-underline h5 action size-guide pointer cl-secondary"
                        >
                          <i class="pr5 material-icons">accessibility</i>
                          <span>{{ $t('Size guide') }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <product-links
              v-if="getCurrentProduct.type_id =='grouped'"
              :products="getCurrentProduct.product_links"
            />
            <product-bundle-options
              v-if="getCurrentProduct.bundle_options && getCurrentProduct.bundle_options.length > 0"
              :product="getCurrentProduct"
            />
            <product-custom-options
              v-else-if="getCurrentProduct.custom_options && getCurrentProduct.custom_options.length > 0"
              :product="getCurrentProduct"
            />
            <no-ssr>
              <div class="row mb35">
                <div class="col-xs-6">
                  <div class="datepick pr5" :class="{ 'datepick-hi': isHighlited.dateFrom, 'disabled': !isCartEmpty }"
                       @click="notifyDates; openDateFrom"
                  >
                    <span class="datepick-label">Zobrazit dostupnost</span>
                    <date-pick
                      @selected="isSelectOpen($event, 'dateFrom')"
                      v-model="dateFrom"
                      ref="dateFrom"
                      :key="compRefreshKey"
                      :date-from="dateFrom"
                      :date-to="dateTo"
                      :display-format="'DD.MM.YYYY'"
                      :pick-time="false"
                      :is-date-disabled="isPastDate"
                      :variant-reservations="getVariantReservations()"
                      :editable="isCartEmpty"
                      :format="'YYYY-MM-DDThh:mm:ss'"
                      :placeholder-text="'OD ?'"
                      :open-on-click="false"
                    />
                  </div>
                </div>
                <div class="col-xs-6 pl5">
                  <div class="datepick" :class="{ 'datepick-hi': isHighlited.dateTo, 'disabled': !isCartEmpty }"
                       @click="notifyDates; openDateTo"
                  >
                    <span class="datepick-label">Zobrazit dostupnost</span>
                    <date-pick
                      @selected="isSelectOpen($event, 'dateTo')"
                      v-model="dateTo"
                      ref="dateTo"
                      :key="compRefreshKey"
                      :date-from="dateFrom"
                      :date-to="dateTo"
                      :display-format="'DD.MM.YYYY'"
                      :pick-time="false"
                      :start-period="getDateToStartPeriod()"
                      :format="'YYYY-MM-DDThh:mm:ss'"
                      :variant-reservations="getVariantReservations()"
                      :is-date-disabled="isDateToAvailable"
                      :editable="isCartEmpty"
                      :placeholder-text="'DO ?'"
                      :open-on-click="false"
                    />
                  </div>
                </div>
              </div>
            </no-ssr>
            <transition name="fade">
              <div class="row mt15 mb40 center-xs" v-if="!isReservationSelected">
                <div class="col-xs-12">
                  <span class="brdr-cl-th-anyrent error-line">{{ $t('you need to select date for reservation') }}</span>
                </div>
              </div>
            </transition>
            <transition name="fade">
              <div class="row mt15 mb40 center-xs" v-if="isReservationSelected && productIntervalError">
                <div class="col-xs-12">
                  <span class="brdr-cl-th-anyrent error-line">{{ $t(productIntervalError) }}</span>
                </div>
              </div>
            </transition>
            <div class="row mt15 mb20 center-xs"
                 v-if="getCurrentProduct.type_id !== 'grouped' && isReservationSelected && !productIntervalError"
            >
              <div class="col-xs-12">
                <product-interval-price
                  class="mt20 mb20"
                  :product="getCurrentProduct"
                  :quantity="Number(getCurrentProduct.qty)"
                  :custom-options="getCurrentCustomOptions"
                  @error="setProductIntervalError"
                />
              </div>
            </div>
            <div class="row align-items-center action-buttons-wrapper reverse-column-xs">
              <div class="col-xs-6">
                <back-button :text="$t('Back to rental').toString()"
                             @click.native="goBack"
                             class="float-left back-button"
                />
              </div>
              <div class="col-xs-6">
                <add-to-cart
                  class="px0 add-to-cart-button"
                  :product="getCurrentProduct"
                  :disabled="isAddToCartDisabled"
                  :is-user-logged="isUserLoggedIn"
                />
              </div>
            </div>
            <div class="row py40 add-to-buttons">
              <div class="col-xs-6 col-sm-3 col-md-6">
              <!-- <AddToWishlist :product="getCurrentProduct" /> -->
              </div>
              <div class="col-xs-6 col-sm-3 col-md-6">
              <!-- <AddToCompare :product="getCurrentProduct" /> -->
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section class="container px15 pt50 pb35 cl-accent details">
      <div class="details-wrapper" :class="{'details-wrapper--open': detailsOpen}">
        <h2 class="h3 m0 mb10 serif lh20">
          {{ $t('Product details') }}
        </h2>
        <div class="row between-md m0">
          <div class="col-xs-12 col-sm-9">
            <div class="lh20 h5 product-description" itemprop="description" v-html="getCurrentProduct.long_description" />
          </div>
          <div class="col-xs-12 col-sm-3">
            <ul class="attributes p0 pt5 m0">
              <product-attribute
                :key="attr.attribute_code"
                v-for="attr in getCustomAttributes"
                :product="getCurrentProduct"
                :attribute="attr"
                empty-placeholder="N/A"
              />
            </ul>
          </div>
          <div class="details-overlay" @click="showDetails" />
        </div>
      </div>
    </section>
    <SizeGuide />
    <PriceList />
    <SetDetails />

    <div class="container">
      <product-detail-tiles />
    </div>
  </div>
</template>

<script>
import config from 'config'
import AddToCart from 'theme/components/core/AddToCart.vue'
import GenericSelector from 'theme/components/core/GenericSelector'
import ColorSelector from 'theme/components/core/ColorSelector.vue'
import SizeSelector from 'theme/components/core/SizeSelector.vue'
import Breadcrumbs from 'theme/components/core/Breadcrumbs.vue'
import ProductAttribute from 'theme/components/core/ProductAttribute.vue'
import ProductLinks from 'theme/components/core/ProductLinks.vue'
import ProductCustomOptions from 'theme/components/core/ProductCustomOptions.vue'
import ProductBundleOptions from 'theme/components/core/ProductBundleOptions.vue'
import ProductGallery from 'theme/components/core/ProductGallery.vue'
// banners
import ProductDetailTiles from 'theme/components/theme/blocks/Product/ProductDetailTiles'
import focusClean from 'theme/components/theme/directives/focusClean'
import SizeGuide from 'theme/components/core/blocks/Product/SizeGuide'
import PriceList from 'theme/components/core/blocks/Product/PriceList'
import SetDetails from 'theme/components/core/blocks/Product/SetDetails.vue'
import { mapGetters } from 'vuex'
import { ProductOption } from '@vue-storefront/core/modules/catalog/components/ProductOption.ts'
import { getAvailableFiltersByProduct, getSelectedFiltersByProduct } from '@vue-storefront/core/modules/catalog/helpers/filters'
import { isOptionAvailableAsync } from '@vue-storefront/core/modules/catalog/helpers/index'
import { htmlDecode } from '@vue-storefront/core/filters'
import { ReviewModule } from '@vue-storefront/core/modules/review'
import { RecentlyViewedModule } from '@vue-storefront/core/modules/recently-viewed'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { onlineHelper, isServer } from '@vue-storefront/core/helpers'
import { catalogHooksExecutors } from '@vue-storefront/core/modules/catalog-next/hooks'
import ProductPrice from 'theme/components/core/ProductPrice.vue'
import ProductIntervalPrice from 'theme/components/core/ProductIntervalPrice.vue'
import DatePick from 'theme/components/theme/anyrent/SearchBar/DatePick.vue'
import {reservationDateLimiter, isDateShown} from 'theme/components/theme/anyrent/helpers/reservationDateLimiter'
import { reservationGetTimeToModifier, reservationSetTimeToModifier, reservationGetTimeFromModifier, reservationSetTimeFromModifier } from 'theme/components/theme/anyrent/helpers/reservationTimeModifier'
import NoSSR from 'vue-no-ssr'

import dayjs from 'dayjs';
import BadgeList from '../components/core/BadgeList';
import ProductBadge from '../components/core/ProductBadge';
import BackButton from '../components/core/blocks/BackButton';
import MapMixin from '../mixins/MapMixin';
import PickupPointModalWindow from '../components/core/blocks/PickupPoint/PickupPointModalWindow';
import PickupPointsViewer from '../components/core/blocks/PickupPoint/PickupPointsViewer';
import ChatBotIcon from '../components/core/blocks/ChatBot/ChatBotIcon';

export default {
  components: {
    ChatBotIcon,
    PickupPointsViewer,
    PickupPointModalWindow,
    BackButton,
    'no-ssr': NoSSR,
    AddToCart,
    Breadcrumbs,
    ColorSelector,
    GenericSelector,
    ProductAttribute,
    ProductBundleOptions,
    ProductCustomOptions,
    ProductGallery,
    ProductLinks,
    SizeSelector,
    SizeGuide,
    ProductPrice,
    ProductIntervalPrice,
    DatePick,
    ProductDetailTiles,
    PriceList,
    SetDetails,
    ProductBadge,
    BadgeList
  },
  mixins: [ProductOption, MapMixin],
  directives: { focusClean },
  beforeCreate () {
    registerModule(ReviewModule)
    registerModule(RecentlyViewedModule)
  },
  data () {
    return {
      detailsOpen: false,
      maxQuantity: 0,
      quantityError: false,
      isStockInfoLoading: false,
      hasAttributesLoaded: false,
      manageQuantity: true,
      compRefreshKey: 0,
      isHighlited: {
        dateFrom: false,
        dateTo: false
      },
      checkQuantity: null,
      productIntervalError: null
    }
  },
  computed: {
    ...mapGetters({
      getCurrentCategory: 'category-next/getCurrentCategory',
      getRoute: 'history/getRoute',
      getCurrentProduct: 'product/getCurrentProduct',
      getProductGallery: 'product/getProductGallery',
      getCurrentProductConfiguration: 'product/getCurrentProductConfiguration',
      getOriginalProduct: 'product/getOriginalProduct',
      attributesByCode: 'attribute/attributeListByCode',
      getCurrentCustomOptions: 'product/getCurrentCustomOptions',
      isCartEmpty: 'cart/isCartEmpty',
      isUserLoggedIn: 'user/isLoggedIn',
      cartItems: 'cart/getCartItems',
      getPartners: 'partner/getPartners',
      getPickupPoints: 'partner/getPickupPoints',
      getCurrentSearchQuery: 'category-next/getCurrentSearchQuery',
      getpreloadedDefaults: 'attribute/getFullPreloadedDefaults',
      getSelectedOptions: 'selectedOptions/getSelectedOptions'
    }),
    partnerPickupPoints () {
      const partnerId = this.getCurrentProduct?.rental_info?.source_id;
      return this.getPickupPoints.filter(pp => pp.partnerId === partnerId);
    },
    getOptionLabel () {
      return (option) => {
        const configName = option.attribute_code ? option.attribute_code : option.label.toLowerCase()
        return this.getCurrentProductConfiguration[configName] ? this.getCurrentProductConfiguration[configName].label : configName
      }
    },
    isOnline (value) {
      return onlineHelper.isOnline
    },
    structuredData () {
      return {
        availability: this.getCurrentProduct.stock && this.getCurrentProduct.stock.is_in_stock ? 'InStock' : 'OutOfStock'
      }
    },
    getProductOptions () {
      if (
        this.getCurrentProduct.errors &&
        Object.keys(this.getCurrentProduct.errors).length &&
        Object.keys(this.getCurrentProductConfiguration).length
      ) {
        return []
      }
      return [...this.getCurrentProduct.configurable_options]
    },
    getOfflineImage () {
      return {
        src: this.getThumbnail(this.getCurrentProduct.image, config.products.thumbnails.width, config.products.thumbnails.height),
        error: this.getThumbnail(this.getCurrentProduct.image, config.products.thumbnails.width, config.products.thumbnails.height),
        loading: this.getThumbnail(this.getCurrentProduct.image, config.products.thumbnails.width, config.products.thumbnails.height)
      }
    },
    getCustomAttributes () {
      return Object.values(this.attributesByCode).filter(a => {
        return a.is_visible && a.is_user_defined && (parseInt(a.is_visible_on_front) || a.is_visible_on_front === true) && this.getCurrentProduct[a.attribute_code]
      }).sort((a, b) => { return a.attribute_id > b.attribute_id })
    },
    getAvailableFilters () {
      return getAvailableFiltersByProduct(this.getCurrentProduct)
    },
    getSelectedFilters () {
      return getSelectedFiltersByProduct(this.getCurrentProduct, this.getCurrentProductConfiguration)
    },
    isSimpleOrConfigurable () {
      return ['simple', 'configurable'].includes(this.getCurrentProduct.type_id)
    },
    isAddToCartDisabled () {
      if (!this.isReservationSelected || this.productIntervalError) {
        return true
      }
      if (this.quantityError || this.isStockInfoLoading) {
        return false
      }
      if (this.checkQuantity <= 0) {
        return true
      }
      // hotfix to disable possibility of adding the same item in cart twice
      for (const item of this.cartItems) {
        if (item.id === this.getCurrentProduct.id) {
          const qtyResolve = this.checkQuantity - item.qty
          if (qtyResolve <= 0) {
            return true
          }
        }
      }
      return this.isOnline && !this.maxQuantity && this.manageQuantity && this.isSimpleOrConfigurable
    },
    openDateFrom () {
      this.$refs.dateFrom.open()
    },
    openDateTo () {
      this.$refs.dateTo.open()
    },
    dateFrom: {
      get () {
        return this.$store.state.reservations.dateFrom;
      },
      set (date) {
        return this.$store.dispatch('reservations/setDateFrom', date)
      }
    },
    dateTo: {
      get () {
        return this.$store.state.reservations.dateTo || '';
      },
      set (date) {
        return this.$store.dispatch('reservations/setDateTo', date)
      }
    },
    isReservationSelected () {
      return !(this.dateFrom === '' || this.dateTo === '');
    }
  },
  async mounted () {
    await this.$store.dispatch('recently-viewed/addItem', this.getCurrentProduct)
    this.checkUrlForReservations()
    this.updatePriceList()
  },
  async asyncData ({ store, route }) { // only on ssr
    const product = await store.dispatch('product/loadProduct', { parentSku: route.params.parentSku, childSku: route && route.params && route.params.childSku ? route.params.childSku : null })
    const loadBreadcrumbsPromise = store.dispatch('product/loadProductBreadcrumbs', { product })
    if (isServer) await loadBreadcrumbsPromise
    catalogHooksExecutors.productPageVisited(product)
  },
  created () {
    this.isThereFilterFromCategory(this.getCurrentProduct)
  },
  beforeRouteEnter (to, from, next) {
    if (isServer) {
      next()
    } else {
      next((vm) => {
        vm.getQuantity()
      })
    }
  },
  watch: {
    isOnline: {
      handler (isOnline) {
        if (isOnline) {
          this.getQuantity()
        }
      }
    },
    dateFrom (val) {
      const variant = this.getSelectedFilters
      this.changeFilter(variant)
      this.compRefreshKey += 1;// vue date pick forced re-render
    },
    dateTo (val) {
      const variant = this.getSelectedFilters
      this.changeFilter(variant)
      this.compRefreshKey += 1;// vue date pick forced re-render
    }
  },
  methods: {
    getDateToStartPeriod () {
      const FORMAT = 'YYYY-MM-DDThh:mm:ss'
      const date = this.dateTo ? dayjs(this.dateTo, FORMAT)
        : this.dateFrom ? dayjs(this.dateFrom, FORMAT) : dayjs()
      return {month: date.month(), year: date.year()}
    },
    goBack () {
      if (this.getRoute.from.fullPath === this.getRoute.to.fullPath) {
        const splitRoute = this.getRoute.from.fullPath.split('/');
        const route = '/' + splitRoute[1];
        this.$router.push(route)
      } else {
        this.$router.go(-1);
      }
    },
    getPartnerName () {
      if (this.getCurrentProduct.rental_info && this.getCurrentProduct.rental_info.name) {
        return this.getCurrentProduct.rental_info.name
      }
      return null
    },
    // is there a filter that was selected while sorting cateories?
    isThereFilterFromCategory (prod) {
      // we do not want to check this if there's less than one
      // conf child.Because there's nowhere to switch
      if (prod.configurable_children.length <= 1) { return }
      // get the selected options
      if (this.getSelectedOptions.length > 0) {
        for (const filter of this.getSelectedOptions) {
          // find the child with suitable option, so we can switch
          const childs = prod.configurable_children.filter(child => child[filter.attribute_code] === parseInt(filter.id));
          if (childs.length > 0) {
            if (filter.attribute_code !== 'price') {
              this.updateOptions(filter)
            }
          }
        }
      }
    },
    updateOptions (filter) {
      this.changeFilter({
        type: filter.type,
        id: parseInt(filter.id),
        label: filter.label
      })
    },
    getFilterLabel (val) {
      const matchingFilter = this.getpreloadedDefaults.find(filter => filter.attribute_code === val);
      if (!matchingFilter) return '...'
      return matchingFilter.default_frontend_label
    },
    checkUrlForReservations () {
      const urlFilters = this.getCurrentSearchQuery
      const hasFrom = urlFilters?.filters?.availability?.from;
      const hasTo = urlFilters?.filters?.availability?.to;

      if (hasTo && hasFrom && !this.isReservationSelected) {
        // check if valid dayjs date
        const isFromValid = dayjs(urlFilters.filters.availability.from).isValid();
        const isToValid = dayjs(urlFilters.filters.availability.to).isValid();
        if (!isToValid || !isFromValid) {
          return
        }
        this.dateFrom = urlFilters.filters.availability.from
        this.dateTo = urlFilters.filters.availability.to
      } else {
      }
    },
    modifySetToDate (date) {
      return reservationSetTimeToModifier(date);
    },
    modifyGetToDate (date) {
      return reservationGetTimeToModifier(date);
    },
    modifyGetFromDate (date) {
      return reservationGetTimeFromModifier(date);
    },
    modifySetFromDate (date) {
      return reservationSetTimeFromModifier(date);
    },
    showDetails (event) {
      this.detailsOpen = true
      event.target.classList.add('hidden')
    },
    notifyOutStock () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t(
          'The product is out of stock and cannot be added to the cart!'
        ),
        action1: { label: this.$t('OK') }
      })
    },
    notifyDates () {
      if (!this.isCartEmpty) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: this.$t(
            'Before changing your dates you need to either finish or cancel your shopping.'
          ),
          action1: { label: this.$t('OK') }
        })
      }
    },
    notifyWrongAttributes () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t(
          'No such configuration for the product. Please do choose another combination of attributes.'
        ),
        action1: { label: this.$t('OK') }
      })
    },
    changeFilter (variant) {
      // send out only when both fields are valid
      if (this.dateFrom !== '' && this.dateTo !== '') {
        this.$bus.$emit('filter-changed-product', Object.assign({ attribute_code: variant.type }, variant))
        this.getQuantity()
      } else {
        // TODO ?
        this.$bus.$emit('filter-changed-product', Object.assign({ attribute_code: variant.type }, variant))
        this.getQuantity()
      }
    },
    hasBadgePricePerSet (product) {
      return !!product.badges && !!product.badges.find(badge => badge.code === 'price-per-set')
    },

    openSizeGuide () {
      this.$bus.$emit('modal-show', 'modal-sizeguide')
    },
    openPriceList () {
      this.updatePriceList()
      this.$bus.$emit('modal-show', 'modal-pricelist')
    },
    openSetDetails () {
      this.$bus.$emit('modal-show', 'modal-setdetails')
    },
    updatePriceList () {
      // update pricelist
      // match childs sku n current sku
      if (this.getCurrentProduct.configurable_children) {
        for (const child of this.getCurrentProduct.configurable_children) {
          if (child.sku === this.getCurrentProduct.sku) {
            if (child.price_list) {
              this.$store.dispatch('product/loadPriceList', child.price_list)
            } else {
              // creating dayprice list
              const dayPrice = [{
                id: 1,
                numberOfDays: 1,
                price: child.price,
                type: 'perDay'
              }]
              this.$store.dispatch('product/loadPriceList', dayPrice)
            }
          }
        }
      } else {
        console.error('product doesnt have configurable children ')
      }
    },
    isOptionAvailable (option) { // check if the option is available
      const currentConfig = Object.assign({}, this.getCurrentProductConfiguration)
      currentConfig[option.type] = option
      return isOptionAvailableAsync(this.$store, { product: this.getCurrentProduct, configuration: currentConfig })
    },
    async getQuantity () {
      // kill this if there's no date
      if (!this.dateTo || !this.dateFrom) {
        return
      }
      if (this.isStockInfoLoading) {
        return // stock info is already loading
      }
      this.isStockInfoLoading = true
      try {
        this.setProductIntervalError('');
        try {
          const res = await this.$store.dispatch('stock/check', {
            product: this.getCurrentProduct,
            qty: this.getCurrentProduct.qty,
            dateFrom: this.dateFrom,
            dateTo: this.dateTo
          });
          if (!res.qty || typeof res === 'string') {
            this.setProductIntervalError('We are sorry, product is not available in this date interval');
            return;
          }
          this.checkQuantity = res.qty;
          // TODO fix properly
          this.manageQuantity = res.isManageStock || null
          this.maxQuantity = res.qty ? res.qty : null

          if (res.qty === 0) {
            this.setProductIntervalError('We are sorry, product is not available in this date interval');
          }
        } catch (e) {
          console.log(e)
        }
      } finally {
        this.isStockInfoLoading = false
      }
    },
    handleQuantityError (error) {
      this.quantityError = error
    },
    isSelectOpen (state, key) {
      this.isHighlited[key] = state
    },
    isDateToAvailable (date) {
      return isDateShown(date, this.dateFrom)
    },
    isPastDate (date) {
      // parsing the dates with dayjs.js
      // limiting users selection
      const now = dayjs()
      const individualDate = dayjs(date)
      const selectedDateTo = dayjs(this.dateTo, 'YYYY-MM-DD-hh-mm')
      // cap how far can be selectable
      const isTooFuture = reservationDateLimiter(date);

      if ((individualDate < now) || (this.dateTo !== '' && date > selectedDateTo) || isTooFuture) {
        return true
      } else {
        return false
      }
    },

    getVariantReservations () {
      const currentProductId = this.getCurrentProduct.id
      const variantChildren = this.getCurrentProduct.configurable_children.find(child => child.id === currentProductId)
      if (variantChildren === undefined) {
        console.error(`Variant with id: ${this.getCurrentProduct.id} not found in configurable children`)
      }
      const reservations = variantChildren.items.reduce((acc, item) => [...acc, ...item.reservations], [])

      return { reservations: reservations, numOfVariantItems: variantChildren.items.length }
    },
    setProductIntervalError (msg) {
      this.productIntervalError = msg
    }
  },
  metaInfo () {
    const childSku = this.$route.query.childSku;
    const url = config.server.websiteUrl + this.$route.fullPath;
    return {
      title: htmlDecode(this.getCurrentProduct.meta_title || this.getCurrentProduct.name),
      meta: this.getCurrentProduct.meta_description ? [
        {
          vmid: 'description',
          name: 'description',
          content: htmlDecode(this.getCurrentProduct.meta_description)
        }
      ] : [],
      link: [
        {
          rel: 'canonical',
          href: (url.indexOf('?') !== -1 ? url.split('?')[0] : url) + (childSku ? '?childSku=' + childSku : '')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-primary: color(primary);
$color-tertiary: color(tertiary);
$color-secondary: color(secondary);
$color-white: color(white);
$color-matterhorn: color(matterhorn);
$bg-secondary: color(secondary, $colors-background);
$color-anyrent: color(anyrent);
$color-white-smoke: color(whitesmoke);

.pricelist {
  color: #4f4f4f;
}
.cl-white-smoke {
  color:rgb(200,200,200);

}

.pricelist:hover {
  color: #4f4f4f !important;
  border-bottom: 1px #bdbdbd solid;
  padding-bottom: 5px !important;
}

.product {
  &__add-to-compare {
    display: none;
    @media (min-width: 767px) {
      display: block;
    }
  }
}

.breadcrumbs {
  font-family: "TTCommons-Medium";
  font-size: 16px;
  @media (max-width: 767px) {
    margin: 15px 0;
    padding: 15px 0 0 15px;
  }
}

.error {
  color: red;
  font-weight: bold;
  padding-bottom: 15px;
}

.data {
  @media (max-width: 767px) {
    border-bottom: 1px solid $bg-secondary;
  }
}

.image {
  @media (max-width: 1023px) {
    margin-bottom: 20px;
  }
}

.product-name {
  font-size: 36px;

  @media (max-width: 767px) {
    font-size: 36px;
  }
}

.variants-label {
  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.variants-wrapper {
  @media (max-width: 767px) {
    padding-bottom: 30px;
  }

  .sizes {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .size-guide {
    height: 40px;
    @media (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
}

.product-top-section {
  @media (max-width: 767px) {
    padding: 0;
    background-color: $color-white;
    background-color: #fff;
  }
}

.add-to-buttons {
  @media (max-width: 767px) {
    padding-top: 30px;
    margin-bottom: 40px;
  }
}

.details {
  @media (max-width: 767px) {
    padding: 50px 15px 15px;
  }
}

.details-title {
  padding: 0 8px;

  @media (max-width: 767px) {
    font-size: 18px;
    margin: 0;
  }
}

.details-wrapper {
  @media (max-width: 767px) {
    position: relative;
    max-height: 140px;
    overflow: hidden;
    transition: all 0.3s ease;
    font-size: 14px;
  }

  &--open {
    max-height: none;
  }
}

.details-overlay {
  @media (max-width: 767px) {
    position: absolute;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    margin: 0;
    cursor: pointer;
    &.hidden {
      display: none;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 75%;
      width: 100%;
      background: linear-gradient(rgba($color-white, 0), rgba($color-white, 1));
    }
  }
}

.action {
  &:hover {
    color: $color-tertiary;
  }
}

.attributes {
  list-style-type: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: all .9s;
}
.fade-leave-active {
  transition: all 0s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-30px);
}

.product-image {
  mix-blend-mode: multiply;
  width: 460px;
}

.web-share {
  float: right;
}
.error-line {
  color:red;
  // border-bottom: 1px solid red;
}

.pricelist:hover {
  color: $color-anyrent;
}

.pricelist > i {
  position: relative;
  top: 6px;
}
</style>

<style lang="scss">
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-anyrent: color(anyrent);
$color-gray: color(gray);
$color-partner: color(partner);

.product-description {
  img,
  video,
  iframe {

    display: block;
    max-width: 100%;
  }
}

/* Datepick styles */

.datepick {
  cursor: pointer;
  border: 1px solid #c7c7c7;
  padding: .4rem .6rem;
  box-sizing: border-box;
}

.datepick .vdpWithInput {
  cursor: pointer;
  width: 100%;
  display: block;
}

.datepick .vdpWithInput > input {
  cursor: pointer;
  outline: none;
  border: 0;
  height: 24px;
  line-height: 1;
  box-sizing: border-box;
  font-family: 'TTCommons-Regular', sans-serif;
  color: #333;
  font-size: 1.45em;
  width: 100%;
}

.datepick .vdpWithInput > input::placeholder {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.datepick-label {
  color: #959595;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  margin-bottom: .2em;
}

.datepick-hi {
  border-color: $color-anyrent;
}

.disabled {
  background-color: rgba(240, 240, 240, 0.5);
  input {
  background-color: inherit;
  opacity: 0.5;
  }
  .vdpInnerWrap {
    cursor: not-allowed;
  }
}

.ml5{
  margin-left: 5px;
}

.grid-2fr {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.info:hover {
  text-decoration: none;
  &:before,
  &:after {
    display: none;
  }
}

.info-label {
  text-decoration: underline;
}

.info-label:hover {
  color: $color-partner;
}

.info-icon,
.info-label {
  vertical-align: middle;
  display: inline-block;
}

.material-icons.info-icon {
  color: $color-gray;
  font-size: 22px;
}

.TTCommons-light{
  font-family: TTCommons-Regular;
}

.TTCommons-medium {
  font-family: TTCommons-Medium;
}
.align-items-start{
  align-items: start;
}
.back-button {
  min-width: 167px;
  height: 42px;
}
.align-items-center {
  align-items: center;
}
.add-to-cart-button {
  min-width: 170px !important;
  width:  170px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.add-to-cart-button::v-deep .wrap {
  width: 124px !important;
}
.px0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media (max-width: 991px) {
  .action-buttons-wrapper {
    flex-direction: row !important;
    align-items: start;
  }
  .reverse-column-xs {
    flex-direction: column-reverse !important;
  }
  .add-to-cart-button {
    margin-left: -2px;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .action-buttons-wrapper {
    flex-direction: row !important;
    align-items: flex-start !important;
  }
  .product-action-button-wrapper {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50% !important;
  }
  .add-to-cart-button {
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .back-button {
    width: 170px;
  }
}

</style>
